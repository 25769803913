import { palette } from '../../theme';
import {
    StyledTile,
    StyledHeadingContainer,
    StyledHeading,
} from './Tile.styled';
import { TileProperties } from './Tile.types';

export const Tile = ({
    children,
    className,
    gutterBottom,
    variant = 'primary',
    title,
    barType = 'info',
}: TileProperties) => {
    const { secondary, success, grey, common, error } = palette;

    const mapOfColors = {
        success: {
            background: success.main,
            textColor: common.white,
        },
        info: { background: grey[100], textColor: common.blue },
        promo: {
            background: secondary.main,
            textColor: common.blue,
        },
        error: { background: error.main, textColor: common.white },
    };

    return (
        <StyledTile
            $gutterBottom={gutterBottom}
            $variant={variant}
            className={className}
        >
            {title && (
                <StyledHeadingContainer
                    $color={mapOfColors[barType].background}
                    data-testid={`barType-${barType}`}
                >
                    <StyledHeading
                        variant="h3"
                        gutterBottom={false}
                        $color={mapOfColors[barType].textColor}
                    >
                        {title}
                    </StyledHeading>
                </StyledHeadingContainer>
            )}
            {children}
        </StyledTile>
    );
};

export default Tile;
