import styled, { css } from 'styled-components';

import { lightGreyBoxShadow, Theme, palette } from '../../theme';
import { Heading } from '../Heading';
import { StyledTileProperties, StyledHeadingProperties } from './Tile.types';

const variants = {
    primary: css`
        box-shadow: ${lightGreyBoxShadow};
    `,
    secondary: css`
        background-color: ${palette.grey[100]};
    `,
} as const;

export const StyledTile = styled.div.attrs({
    'data-testid': 'tile-container',
})<StyledTileProperties>`
    display: inline-block;
    width: 100%;
    margin-bottom: ${({ $gutterBottom }) => $gutterBottom && '3rem;'};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: ${({ $gutterBottom }) => $gutterBottom && '4rem;'};
    }
    border-radius: 0.8rem;
    ${({ $variant }) => variants[$variant]};
`;

export const StyledHeadingContainer = styled.div<StyledHeadingProperties>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(20)}
        ${({ theme }) => theme.spacing(30)};
    text-align: center;
    background-color: ${({ $color }) => $color};
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: ${({ theme }) => theme.spacing(30)}
            ${({ theme }) => theme.spacing(40)};
    }
`;

export const StyledHeading = styled(Heading)<StyledHeadingProperties>`
    color: ${({ $color }) => $color};
    text-transform: lowercase;
`;
