import { Paragraph } from '../../Paragraph';
import { PaymentTile } from '../PaymentTile';
import { MonthlyPaymentTileDescription } from './MonthlyPaymentTileDescription';
import {
    getDiscountDuration,
    mapSteppedKickerForPaymentSummary,
} from './PaymentSummary.helpers';
import { PaymentSummaryProperties } from './PaymentSummary.types';
import { GymStatus } from '@tgg/common-types';

const getMonthlyDescription = (isOpeningSoon: boolean) => (
    <Paragraph gutterBottom={false}>
        Subsequent monthly payments will {isOpeningSoon ? 'start' : 'commence'}{' '}
        one month from {isOpeningSoon ? 'the gym opening date' : 'today'}.
        Payments will then continue on or just after the same day of each month
        thereafter.
    </Paragraph>
);

export const PaymentSummary = ({
    todayTileDescription,
    monthlyTileDescription,
    membershipStartDate,
    branchStatus,
    ...payments
}: PaymentSummaryProperties) => {
    const { monthly, today, steppedKicker } = payments;
    const displayTodayPayments = today && today.items.length > 0;
    const displayMonthlyPayments = monthly && monthly.items.length > 0;
    const steppedKickerPaymentItems =
        steppedKicker && mapSteppedKickerForPaymentSummary(steppedKicker);
    const discountDuration = getDiscountDuration(steppedKicker);
    const isOpeningSoon =
        branchStatus !== undefined && branchStatus === GymStatus.ComingSoon;

    return (
        <>
            {displayTodayPayments && (
                <PaymentTile
                    title="Today's Payment"
                    items={today.items}
                    description={todayTileDescription}
                    total={today.total}
                    gutterBottom={displayMonthlyPayments || !!steppedKicker}
                />
            )}

            {displayMonthlyPayments && (
                <PaymentTile
                    title="Monthly Payments"
                    items={monthly.items}
                    description={
                        monthlyTileDescription ||
                        getMonthlyDescription(isOpeningSoon)
                    }
                    total={monthly.total}
                />
            )}

            {steppedKickerPaymentItems && (
                <PaymentTile
                    title="Monthly Payments"
                    steppedKickerPaymentItems={steppedKickerPaymentItems}
                    description={
                        <MonthlyPaymentTileDescription
                            isSteppedKickerPromo={!!steppedKicker}
                            discountDuration={discountDuration}
                            membershipStartDate={membershipStartDate}
                            isOpeningSoon={isOpeningSoon}
                        />
                    }
                />
            )}
        </>
    );
};
