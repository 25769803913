import { ReactNode } from 'react';

import { FromArray } from './utility.types';

export const benefitsHeadings = [
    'Free classes',
    'Gym Access',
    'Multi-gym access',
    'Freeze your monthly membership once for free',
    'Bring a friend 4 times per month',
    'Free Fiit workouts & plans worth £20 a month',
    'Fitness & body composition tracking (where available)',
] as const;

export const studentBenefitsHeadings = [
    'Free classes',
    'Gym Access',
    'Multi-gym access',
    'Discounts & offers',
    'Fitness & body composition tracking (where available)',
] as const;

export const dayPassBenefitsHeadings = [
    'Unlimited training',
    'Free classes',
    'Single-gym access',
] as const;

export const studentProductTitle = 'student';

export type Benefits = FromArray<typeof benefitsHeadings>;

export type BenefitsHeadings = Benefits | 'Additional benefits';

export enum ProductVariant {
    Monthly = 'Monthly',
    Student = 'Student',
    DayPass = 'DayPass',
    None = 'None',
    Fixed = 'Fixed',
}

export const productTitle: Record<string, string> = {
    [ProductVariant.Student]: 'Student',
    [ProductVariant.DayPass]: 'Day passes',
} as const;

export interface DayPassAvailability {
    isDisabled: boolean;
    startDate: string;
}

export type ProductClassification = 'Pino' | 'Legacy';

export type ExpectedPaymentFrequency = 'OneOff' | 'Monthly';

export enum TggMembershipType {
    Rollover = 'Rollover',
    // eslint-disable-next-line camelcase
    Do_It = 'Do_It',
    // eslint-disable-next-line camelcase
    Live_It = 'Live_It',
    StudentFitquestGymAccess = 'StudentFitquestGymAccess',
    Student3 = 'Student3',
    Student6 = 'Student6',
    Student9 = 'Student9',
    Student12 = 'Student12',
    DayPass3 = 'DayPass3',
    DayPass = 'DayPass',
    DayPass5 = 'DayPass5',
    Staff = 'Staff',
    Live_It_Fixed = 'Live_It_Fixed',
    UltimateMonthly = 'UltimateMonthly',
    UltimateFixed3 = 'UltimateFixed3',
    UltimateFixed6 = 'UltimateFixed6',
    UltimateFixed9 = 'UltimateFixed9',
    UltimateFixed12 = 'UltimateFixed12',
    StandardMonthly = 'StandardMonthly',
    StandardFixed3 = 'StandardFixed3',
    StandardFixed6 = 'StandardFixed6',
    StandardFixed9 = 'StandardFixed9',
    StandardFixed12 = 'StandardFixed12',
    OffPeakMonthly = 'OffPeakMonthly',
    OffPeakFixed3 = 'OffPeakFixed3',
    OffPeakFixed6 = 'OffPeakFixed6',
    OffPeakFixed9 = 'OffPeakFixed9',
    OffPeakFixed12 = 'OffPeakFixed12',
}

export enum TggAddonMembershipType {
    SportsWaterYanga = 'SportsWaterYanga',
}

/**
 * Users status of the membership
 */
export enum TggMemberStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    Hold = 'Hold',
    Casual = 'Casual',
    Deleted = 'Deleted',
    Pending = 'Pending',
    Prospect = 'Prospect',
}

/**
 * Gym product returned by getJoinOptions service
 */
export type GymProduct = {
    id: string;
    variant: ProductVariant;
    joiningFee: number | null;
    price: number;
    description: string;
    expectedPaymentFrequency: ExpectedPaymentFrequency;
    termType: string;
    term: number;
    tggMembershipType: TggMembershipType;
    dayPassDays: DayPassAvailability[];
    isKickerPriceInForce: boolean;
    kickerPriceDuration: number | null;
    kickerPriceMonthlyFee: number | null;
    classification: ProductClassification;
};

export interface BaseProduct<T> {
    id: string;
    description: string;
    variant: ProductVariant;
    price: MembershipPrice;
    expectedPaymentFrequency: 'OneOff' | 'Monthly';
    joiningFee?: number; // This is 'any' in the service, is that correct?
    dayPassDays: DayPassAvailability[] | null;
    isCurrentPlan?: boolean;
    membershipType: T;
    classification: ProductClassification;
}

/**
 * The product used by the UI.
 */
export type Product = BaseProduct<TggMembershipType>;

/**
 * The collection of products used by the UI.
 */
export interface Products {
    [ProductVariant.Monthly]: Partial<MonthlyProducts>;
    [ProductVariant.Student]: Product[];
    [ProductVariant.DayPass]: Product[];
    [ProductVariant.Fixed]: Product[];
}

export interface MonthlyProducts {
    [TggMembershipType.Do_It]: Product;
    [TggMembershipType.Live_It]: Product;
    [TggMembershipType.OffPeakMonthly]: Product;
    [TggMembershipType.StandardMonthly]: Product;
    [TggMembershipType.UltimateMonthly]: Product;
}

export type MonthlyProduct =
    | TggMembershipType.Do_It
    | TggMembershipType.Live_It
    | TggMembershipType.OffPeakMonthly
    | TggMembershipType.StandardMonthly
    | TggMembershipType.UltimateMonthly;

export type PinoProductTypes =
    | TggMembershipType.UltimateMonthly
    | TggMembershipType.StandardMonthly
    | TggMembershipType.OffPeakMonthly
    | TggMembershipType.StudentFitquestGymAccess
    | TggMembershipType.Student3
    | TggMembershipType.Student6
    | TggMembershipType.Student9
    | TggMembershipType.Student12
    | TggMembershipType.DayPass
    | TggMembershipType.DayPass3
    | TggMembershipType.DayPass5;

/**
 * The price of a membership.
 */
export interface MembershipPrice {
    value: number;
    originalValue?: number | null;
    frequency: OneOffPaymentFrequency | MonthlyPaymentFrequency;
    isKickerPriceInForce: boolean;
    kickerPriceDuration: number | null;
    kickerPriceMonthlyFee: number | null;
}

export interface MonthlyPaymentFrequency {
    paymentFrequency: 'Monthly';

    term: number;
    termType?: 'Months' | 'Days';
}

export interface OneOffPaymentFrequency {
    paymentFrequency: 'OneOff';
    term: number;
    termType: 'Months' | 'Days';
}

export interface AddOn {
    id: string;
    description: string | null;
    termType: 'Months' | 'Days';
    expectedPaymentAmount: number;
    expectedPaymentFrequency: ExpectedPaymentFrequency;
    tggMembershipType: 'SportsWaterYanga';
    kickerPriceMonthlyFee: number | null;
}

/*  PINO Shared Types */
export enum TggPinoProductTitle {
    Ultimate = 'ultimate',
    OffPeak = 'off-peak',
    Standard = 'standard',
    Student = 'membershipStudent',
    DayPass = 'dayPass',
}

export type PinoProductTitle = TggPinoProductTitle;

export enum TggProductRange {
    Ultimate = 'ultimate',
    OffPeak = 'off peak',
    Standard = 'standard',
    Student = 'student',
}

export type PinoDetails = {
    includedBenefits: IncludedBenefits;
    title: PinoProductTitle;

    position: number;
};

export type PinoProductDetails = {
    [key in TggPinoMembershipType]: PinoDetails;
};

export type PinoProduct = BaseProduct<TggPinoMembershipType>;

export type PinoMonthlyProduct = BaseProduct<PinoProductTypes>;

export type TggPinoMembershipType =
    | TggMembershipType.UltimateMonthly
    | TggMembershipType.UltimateFixed12
    | TggMembershipType.UltimateFixed9
    | TggMembershipType.UltimateFixed6
    | TggMembershipType.UltimateFixed3
    | TggMembershipType.StandardMonthly
    | TggMembershipType.StandardFixed12
    | TggMembershipType.StandardFixed9
    | TggMembershipType.StandardFixed6
    | TggMembershipType.StandardFixed3
    | TggMembershipType.OffPeakMonthly
    | TggMembershipType.OffPeakFixed12
    | TggMembershipType.OffPeakFixed9
    | TggMembershipType.OffPeakFixed6
    | TggMembershipType.OffPeakFixed3
    | TggMembershipType.StudentFitquestGymAccess
    | TggMembershipType.Student3
    | TggMembershipType.Student6
    | TggMembershipType.Student9
    | TggMembershipType.Student12
    | TggMembershipType.DayPass
    | TggMembershipType.DayPass3
    | TggMembershipType.DayPass5;

export interface PinoMonthlyProductsWithMonthlyVariant {
    [ProductVariant.Monthly]: PinoProductsWithMonthlyVariant;
    [ProductVariant.Student]: Product[];
    [ProductVariant.DayPass]: Product[];
    [ProductVariant.Fixed]: Product[];
}

export interface PinoProductsWithMonthlyVariant {
    [TggMembershipType.OffPeakMonthly]?: PinoProduct;
    [TggMembershipType.StandardMonthly]: PinoProduct;
    [TggMembershipType.UltimateMonthly]: PinoProduct;
}

export type IncludedBenefits = StandardBenefits | StudentBenefits;

export type StandardBenefits = {
    [key in BenefitsHeadings]: includedBenefitsDisplay;
};

export type StudentBenefits = {
    [key: string]: includedBenefitsDisplay;
};

export interface includedBenefitsDisplay {
    rowSpan: number;
    display: ReactNode;
    linkHref?: string;
}
