import styled from 'styled-components';

import { Theme, palette } from '../../../theme';

export const StyledHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6.1rem;
    padding: ${({ theme }) => theme.spacing(15)};
    background-color: ${palette.grey[100]};
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
`;

export const StyledBody = styled.div`
    padding-right: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(15)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-right: ${({ theme }) => theme.spacing(30)};
        padding-left: ${({ theme }) => theme.spacing(30)};
    }
`;

export const StyledDescription = styled.div`
    b {
        font-family: thegymgroupsans-Headline, sans-serif;
    }
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: left;
    }
`;
